import { LocationDescriptor } from "@naf/teamscheme";
import { ReactNode } from "react";
import styled from "styled-components";
import Breadcrumbs from "../../shared/elements/Breadcrumbs";
import { useContractToken } from "../ContractParams";
import { useContractUrls } from "../routing/ContractUrls";
import { AppPages } from "../../routing/AppPages";

function useContractLandingPageLocationDescriptor() {
  const token = useContractToken();
  const urls = useContractUrls();
  const params = new URLSearchParams();
  if (token) {
    params.set("token", token.value);
  }
  const descriptor: LocationDescriptor = {
    ...urls[AppPages.Contract],
    search: params.toString(),
  };

  return descriptor;
}

export function useContactRootBreadcrumbs() {
  const path = useContractLandingPageLocationDescriptor();
  return { action: { linkTo: path }, label: "Din oversikt" };
}

const ReturnLinkWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.space12};
`;

function ReturnToLandingPage() {
  const rootBreadcrumbs = useContactRootBreadcrumbs();
  return (
    <ReturnLinkWrapper>
      <Breadcrumbs items={[rootBreadcrumbs]} />
    </ReturnLinkWrapper>
  );
}

export default ReturnToLandingPage;

export function ContractBreadcrumb({ title }: { title?: ReactNode }) {
  const rootBreadcrumbs = useContactRootBreadcrumbs();
  const currentPage = {
    label: title,
  };
  return (
    <ReturnLinkWrapper>
      <Breadcrumbs items={[rootBreadcrumbs]} currentPage={currentPage} />
    </ReturnLinkWrapper>
  );
}
