import { Close } from "@styled-icons/material/Close";
import {
  HTMLAttributes,
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ReactNode } from "react";
import { useContext } from "react";
import styled from "styled-components";
import { EVENTS, useTracking } from "../../Tracking";
import { useApiClient } from "../../api/ApiClient";
import { useClientBase } from "../../api/ClientBase";
import { isPrerendering } from "../../shared/utils/Prerendering";
import { flowHeaderPaddingHorizontal } from "./HeaderPadding";
import { HeaderVariant } from "./HeaderVariant";

interface GlobalAlert {
  id: string;
  title: string;
  htmlContent: string;
}

interface HeaderAlertProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {
  alert: GlobalAlert;
  variant: HeaderVariant;
}

function hiddenKey(alert: GlobalAlert) {
  return `GlobalAlert.${alert.id}.hidden`;
}

function isHidden(alert: GlobalAlert) {
  return !!localStorage.getItem(hiddenKey(alert));
}

function hide(alert: GlobalAlert) {
  return localStorage.setItem(hiddenKey(alert), new Date().toISOString());
}

interface IHeaderAlertsContext {
  alerts: GlobalAlert[];
}

interface GetAlertsResponse {
  alerts: GlobalAlert[];
}

const HeaderAlertsContext = createContext<IHeaderAlertsContext | null>(null);
export function HeaderAlertsProvider({ children }: { children: ReactNode }) {
  const { get } = useApiClient();
  const client = useClientBase<void, GetAlertsResponse>(
    useCallback(() => get("alert"), [get]),
  );
  const { fetch } = client;
  useEffect(() => {
    if (!isPrerendering()) fetch();
  }, [fetch]);

  return (
    <HeaderAlertsContext.Provider value={client.result}>
      {children}
    </HeaderAlertsContext.Provider>
  );
}

export function HeaderAlerts({ variant }: { variant: HeaderVariant }) {
  const context = useContext(HeaderAlertsContext);
  const alerts = context?.alerts || [];
  return (
    <HeaderAlertWrapper>
      {alerts.map((alert) => (
        <HeaderAlert key={alert.id} alert={alert} variant={variant} />
      ))}
    </HeaderAlertWrapper>
  );
}

export const HeaderAlert = styled(
  ({ alert, variant: _, ...props }: HeaderAlertProps) => {
    const [isVisible, setIsVisible] = useState(
      !isHidden(alert) && !isPrerendering(),
    );
    const tracking = useTracking();
    function close() {
      setIsVisible(false);
      hide(alert);
      tracking.event({
        ...EVENTS.GLOBAL_ALERT.HIDE,
        label: alert.title,
        value: alert.id,
      });
    }
    if (!isVisible) return null;
    return (
      <div {...props}>
        <header>
          <h2>{alert.title}</h2>
          <button type="button" onClick={close} aria-label="Lukk">
            <Close width={16} />
          </button>
        </header>
        {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
        <div dangerouslySetInnerHTML={{ __html: alert.htmlContent }} />
      </div>
    );
  },
)`
  margin: 0 auto;
  max-width: ${(props) => props.theme.skeleton.maxWidth};
  padding-top: ${({ theme }) => theme.spacing.space16};
  padding-bottom: ${({ theme }) => theme.spacing.space16};

  & > header,
  & > div {
    ${({ theme }) => flowHeaderPaddingHorizontal(theme)}
  }

  h2 {
    ${({ theme }) => theme.fontStyle.article.articleTextHeader};
  }

  h2,
  p {
    margin: ${({ theme }) => theme.spacing.space8} 0;
  }

  a {
    text-decoration: underline;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      display: block;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      cursor: pointer;

      svg {
        display: block;
      }
    }
  }
`;

const HeaderAlertWrapper = styled.div`
  background: ${({ theme }) => theme.nafColor.information.warningLight};
  & > ${HeaderAlert}:not(:first-child) {
    padding-top: 0;
  }
`;
