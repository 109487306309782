import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { type ContractToken } from "../contract/ContractToken";
import { useLoadContractContext } from "../contract/LoadContractContext";
import { type HttpMethod } from "../shared/models/HttpMethod";
import { type Cancelable, makeCancelable } from "../utils";
import { ApiError, useApiClient } from "./ApiClient";

export const BUYER_TOKEN_HEADER = "X-Buyer-Token";
export const SELLER_TOKEN_HEADER = "X-Seller-Token";

export interface IContractClientContext {
  get<T>(uri: string): Promise<T>;
  post<T>(uri: string, content?: unknown): Promise<T>;
  put<T>(uri: string, content?: unknown): Promise<T>;
  delete<T>(uri: string, content?: unknown): Promise<T>;
  patch<T>(uri: string, content?: unknown): Promise<T>;
}

export const ContractClientContext =
  createContext<IContractClientContext | null>(null);

export function getTokenHeader(token: ContractToken): {
  [key: string]: string;
} {
  switch (token.role) {
    case Role.Buyer:
      return { [BUYER_TOKEN_HEADER]: token.value };
    case Role.Seller:
      return { [SELLER_TOKEN_HEADER]: token.value };
    default:
      throw new Error(`Unrecognized role ${token.role}`);
  }
}

export function useContractClient() {
  const context = useContext(ContractClientContext);

  if (!context) throw new Error("No ContractClientContext found!");

  return context;
}

export function ContractClientProvider({ children }: { children: ReactNode }) {
  const { contract } = useLoadContractContext();
  const buyerToken =
    contract?.data && "buyerToken" in contract.data
      ? contract?.data.buyerToken
      : null;
  const sellerToken =
    contract?.data && "sellerToken" in contract.data
      ? contract?.data.sellerToken
      : null;
  const apiClient = useApiClient();
  const contractToken = useMemo(
    () =>
      buyerToken
        ? { role: Role.Buyer, value: buyerToken }
        : sellerToken
          ? { role: Role.Seller, value: sellerToken }
          : null,
    [buyerToken, sellerToken],
  );
  const tokenHeader = useMemo(
    () => (contractToken ? getTokenHeader(contractToken) : {}),
    [contractToken],
  );

  const send = useCallback(
    function <T>(method: HttpMethod, uri: string, content?: unknown) {
      const headers = {
        ...tokenHeader,
      };
      return apiClient.send<T>(method, uri, content, headers);
    },
    [apiClient, tokenHeader],
  );

  const context = useMemo(
    () => ({
      send,
      get: <T,>(uri: string) => send<T>("GET", uri),
      post: <T,>(uri: string, content?: unknown) =>
        send<T>("POST", uri, content),
      put: <T,>(uri: string, content?: unknown) => send<T>("PUT", uri, content),
      delete: <T,>(uri: string, content?: unknown) =>
        send<T>("DELETE", uri, content),
      patch: <T,>(uri: string, content?: unknown) =>
        send<T>("PATCH", uri, content),
    }),
    [send],
  );
  return (
    <ContractClientContext.Provider value={context}>
      {children}
    </ContractClientContext.Provider>
  );
}

export interface ImmutableClient<T> {
  isLoading: boolean;
  result: T | null;
  error: ApiError | null;
  refetch(): Promise<T | null>;
}

export function useGetContractPath<T>({
  path,
}: {
  path: string | null;
}): ImmutableClient<T> {
  const client = useContractClient();
  const [isLoading, setIsLoading] = useState(!!path);
  const [result, setResult] = useState<T | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const execute = useCallback(async function execute(
    cancelable: Cancelable<T>,
  ) {
    setIsLoading(true);
    setError(null);

    try {
      const result = await cancelable.promise;

      setResult(result);
    } catch (error) {
      const typedError = error as { isCanceled: true } | ApiError;
      if (!("isCanceled" in typedError && typedError.isCanceled)) {
        setError(typedError as ApiError);
      }
    } finally {
      if (!cancelable.isCanceled) {
        setIsLoading(false);
      }
    }
  }, []);

  const { get } = client;

  useEffect(() => {
    if (!path) return;

    const cancelable = makeCancelable(get<T>(path));

    execute(cancelable);

    return () => cancelable.cancel();
  }, [get, execute, path]);

  return {
    isLoading,
    result,
    error,
    refetch: function () {
      if (!path) return Promise.resolve(result);

      const cancelable = makeCancelable(get<T>(path));

      execute(cancelable);

      return cancelable.promise;
    },
  };
}
