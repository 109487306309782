import React, { useEffect, useCallback, ReactNode, useState } from "react";
import { Location, useLocation } from "react-router-dom";
import { useTracking } from "../Tracking";

export function anonymizeContractPath(pathname: string) {
  return pathname
    .replace(/\/t\/[^/$]+((\/[^/]+)*$)?/, function (_, end) {
      return `/t/:token${end || ""}`;
    })
    .replace(/\/signer\/[^/$]+((\/[^/]+)*$)?/, function (_, end) {
      return `/signer/:hash${end || ""}`;
    })
    .replace(/\/signering\/[^/$]+((\/[^/]+)*$)?/, function (_, end) {
      return `/signering/:token${end || ""}`;
    });
}

export interface IPageViewContext {
  trackLocation(location: Location): void;
}

export const PageViewContext = React.createContext<IPageViewContext | null>(
  null,
);

export function TrackPageViews({ children }: { children: ReactNode }) {
  const normalizePath = anonymizeContractPath;
  const [currentPath, setCurrentPath] = useState<string>("");
  const location = useLocation();
  const tracking = useTracking();
  const isQuickLink = location.pathname.startsWith("/t/");

  const trackLocation = useCallback(
    function trackLocation(location: Location) {
      if (!isQuickLink) {
        tracking.pageView({
          path: normalizePath(location.pathname) || "/",
          title: document.title.replace(/[A-Za-z]{1,2}\d{3,5}/i, "{plate}"),
        });
      }
    },
    [isQuickLink, tracking, normalizePath],
  );

  useEffect(() => {
    // execute on location change
    if (currentPath !== location.pathname) {
      trackLocation(location);
      setCurrentPath(location.pathname);
    }
  }, [location, trackLocation, currentPath]);

  useEffect(() => {
    tracking.appLoad();
  }, [tracking]);

  return (
    <PageViewContext.Provider
      value={{
        trackLocation,
      }}
    >
      {children}
    </PageViewContext.Provider>
  );
}
