import { stylesheet } from "@naf/global-style";
import { onDesktop } from "@naf/teamscheme-core";
import { createGlobalStyle, css } from "styled-components";

import QuestaSans900NormalWoff2 from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-900-normal.woff2";
import QuestaSans900NormalWoff from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-900-normal.woff";
import QuestaSans700NormalWoff2 from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-700-normal.woff2";
import QuestaSans700NormalWoff from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-700-normal.woff";
import QuestaSans500NormalWoff2 from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-500-normal.woff2";
import QuestaSans500NormalWoff from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-500-normal.woff";
import QuestaSans400NormalWoff2 from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-400-normal.woff2";
import QuestaSans400NormalWoff from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-400-normal.woff";
import QuestaSans300ItalicWoff2 from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-300-italic.woff2";
import QuestaSans300ItalicWoff from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-300-italic.woff";
import QuestaSans300NormalWoff2 from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-300-normal.woff2";
import QuestaSans300NormalWoff from "../../node_modules/@naf/fonts/src/fonts/questa/questa-sans-300-normal.woff";
import QuestaSlab400ItalicWoff2 from "../../node_modules/@naf/fonts/src/fonts/questa/questa-slab-400-italic.woff2";
import QuestaSlab400ItalicWoff from "../../node_modules/@naf/fonts/src/fonts/questa/questa-slab-400-italic.woff";

export const GlobalStyle = createGlobalStyle`
  ${stylesheet}

  html, body {
    overflow-x: initial;
  }

  @font-face {
    font-family: "questa-sans";
    src: url("${QuestaSans900NormalWoff2}") format("woff2"),
      url("${QuestaSans900NormalWoff}") format("woff");
    font-display: swap;
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: "questa-sans";
    src: url("${QuestaSans700NormalWoff2}") format("woff2"),
      url("${QuestaSans700NormalWoff}") format("woff");
    font-display: swap;
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "questa-sans";
    src: url("${QuestaSans500NormalWoff2}") format("woff2"),
      url("${QuestaSans500NormalWoff}") format("woff");
    font-display: swap;
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "questa-sans";
    src: url("${QuestaSans400NormalWoff2}") format("woff2"),
      url("${QuestaSans400NormalWoff}") format("woff");
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: "questa-sans";
    src: url("${QuestaSans300ItalicWoff2}") format("woff2"),
      url("${QuestaSans300ItalicWoff}") format("woff");
    font-display: swap;
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: "questa-sans";
    src: url("${QuestaSans300NormalWoff2}") format("woff2"),
      url("${QuestaSans300NormalWoff}") format("woff");
    font-display: swap;
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "questa-slab";
    src: url("${QuestaSlab400ItalicWoff2}") format("woff2"),
      url("${QuestaSlab400ItalicWoff}") format("woff");
    font-display: swap;
    font-weight: 400;
    font-style: italic;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin-top: 0;
  }

  body {
    margin: 0;
    padding: 0;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  input[type="date"],
  input[type="time"],
  input[type="button"],
  input[type="submit"],
  button,
  textarea {
    font-family:
      questa-sans,
      -system-ui,
      system-ui,
      sans-serif;
  }

  a {
    word-break: break-word;
  }

  * {
    box-sizing: border-box;
  }

  .App > footer > *:last-child {
    margin-bottom: 0;
  }

  table th {
    text-align: left;
  }


  ${(props) => {
    const theme = props.theme;

    return css`
      a {
        color: ${theme.nafColor.signature.black};
        text-decoration: none;
      }

      a:focus {
        outline: 2px dotted ${theme.focus.color};
        outline-offset: 1px;
      }

      button {
        transition: box-shadow 0.1s ease;
        text-decoration: none;
      }

      button:hover,
      a:hover {
        color: ${theme.nafColor.primary.park};
      }

      button:focus {
        box-shadow: 0 0 0 3px ${theme.focus.color};
        outline: none;
      }

      button:focus::-moz-focus-inner {
        border: none;
      }

      button:-moz-focusring,
      [type="button"]:-moz-focusring,
      [type="reset"]:-moz-focusring,
      [type="submit"]:-moz-focusring {
        outline: none;
      }

      b,
      strong {
        font-weight: bold;
      }

      h1 {
        ${theme.mobileFontStyle.headers.header1};
        ${onDesktop`
          ${theme.fontStyle.headers.header1}
        `}
      }

      h2 {
        ${theme.mobileFontStyle.headers.header2};
        ${onDesktop`
          ${theme.fontStyle.headers.header2}
        `}
      }

      h3 {
        ${theme.mobileFontStyle.headers.header3};
        ${onDesktop`
          ${theme.fontStyle.headers.header3}
        `}
      }
    `;
  }}
`;
