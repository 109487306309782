import { HTMLAttributes } from "react";
import styled from "styled-components";

export interface ICopyFooter {
  year: number;
  appVersion: string;
}

export const copyFooterData: ICopyFooter = {
  year: new Date().getFullYear(),
  appVersion: import.meta.env.PUBLIC_ENV__REACT_APP_VERSION,
};

export const CopyFooter = styled(function ({
  className,
}: Omit<HTMLAttributes<HTMLDivElement>, "children">) {
  const { year, appVersion } = copyFooterData;
  return (
    <footer className={className}>
      <p>
        &copy; {year} NAF v{appVersion}
      </p>
    </footer>
  );
})`
  p {
    margin: 0;
    background-color: white;
    padding: 0.5em 1em;
    ${({ theme }) => theme.fontStyle.bodyText.tiny};
  }
`;
