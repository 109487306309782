import { useMemo } from "react";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { useContract } from "./ContractContext";
import { type ContractToken } from "./ContractToken";
import { type ContractViewModel } from "./model/ContractViewModel";

export function getTokenFromContract(
  contract: ContractViewModel,
): ContractToken {
  return "buyerToken" in contract
    ? { role: Role.Buyer, value: contract.buyerToken }
    : { role: Role.Seller, value: contract.sellerToken };
}

export function useContractToken(): ContractToken {
  const contract = useContract();

  return useMemo(() => getTokenFromContract(contract), [contract]);
}
