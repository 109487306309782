import {
  Auth0Client,
  Auth0ClientOptions,
  IdToken,
  RedirectLoginOptions,
} from "@auth0/auth0-spa-js";
import { LocationDescriptor } from "@naf/teamscheme";
import {
  ReactNode,
  createContext,
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { useTrackContext } from "../Tracking";
import { useSentryTag } from "../useSentryTag";
import { RETURN_PATH_PARAM } from "../shared/utils/Location";

export interface LoginOptions {
  targetUrl: string;
  locationState?: unknown;
  options?: Omit<RedirectLoginOptions, "appState" | "redirect_uri">;
}

export interface IAuthContext {
  isLoading: boolean;
  isInitialized: boolean;
  isAuthenticated: boolean;
  email: string | null;
  isAdmin: boolean;
  appState: unknown | null;
  jwt: string | null;
  loginWithRedirect(props: LoginOptions): void;
  logout(): void;
  handleRedirectCallback(): Promise<void>;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export function useAuth() {
  const auth0Context = useContext(AuthContext);
  if (!auth0Context) throw new Error("No Auth0Context found!");
  return auth0Context;
}

export function SsrAuthProvider({ children }: { children: ReactNode }) {
  return (
    <AuthContext.Provider
      value={{
        isLoading: false,
        isInitialized: false,
        jwt: null,
        email: null,
        isAdmin: false,
        isAuthenticated: false,
        appState: null,
        loginWithRedirect() {},
        logout() {},
        handleRedirectCallback() {
          return Promise.resolve();
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function Auth0AuthProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [jwt, setJwt] = useState<string | null>(null);
  const [idTokenClaims, setIdTokenClaims] = useState<IdToken | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [appState, setAppState] = useState<unknown | null>(null);
  const location = useLocation();

  const [auth0Client] = useState(() => {
    if (typeof window === "undefined") return;

    const location = window.location;
    const options: Auth0ClientOptions =
      location.host === "kjopekontrakt.naf.no"
        ? {
            domain: import.meta.env.VITE_AUTH0_PROD_DOMAIN,
            clientId: import.meta.env.VITE_AUTH0_PROD_CLIENT_ID,
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_PROD_AUDIENCE,
            },
          }
        : {
            domain: import.meta.env.VITE_AUTH0_STAGE_DOMAIN,
            clientId: import.meta.env.VITE_AUTH0_STAGE_CLIENT_ID,
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_STAGE_AUDIENCE,
            },
          };
    const initOptions: Auth0ClientOptions = {
      ...options,
      authorizationParams: {
        ...options.authorizationParams,
        redirect_uri: `${location.origin}/login/callback`,
        scope: "email",
      },
      useCookiesForTransactions: true,
      cacheLocation: "localstorage",
      useRefreshTokensFallback: true,
      useRefreshTokens: true,
    };
    const client = new Auth0Client({
      ...initOptions,
    });

    return client;
  });

  useEffect(() => {
    const initAuth0 = async (client: Auth0Client) => {
      setIsLoading(true);

      if (typeof window !== "undefined") {
        try {
          const access_token = await client.getTokenSilently();
          setJwt(access_token);

          const id_token = await client.getIdTokenClaims();
          setIdTokenClaims(id_token ?? null);
        } catch (error) {
          if ((error as { error: string }).error !== "login_required") {
            throw error;
          }
        }
        const authenticated = await client.isAuthenticated();
        setIsAuthenticated(authenticated);
      }

      setIsLoading(false);
      startTransition(() => setIsInitialized(true));
    };

    if (!auth0Client) return;

    initAuth0(auth0Client);
  }, [auth0Client]);

  const navigate = useNavigate();
  const onRedirectCallback = useCallback(
    (appState?: { targetUrl: string } | { locationState: unknown }) => {
      const next: LocationDescriptor = {
        pathname: location.pathname,
        state: null,
        search: undefined,
      };
      if (appState && typeof appState === "object") {
        if ("targetUrl" in appState) {
          const [path, search] = appState.targetUrl.split("?");
          next.pathname = path;
          next.search = search ? `?${search}` : undefined;
        }
        if ("locationState" in appState) {
          next.state = appState.locationState;
        }
      }
      navigate(next);
    },
    [navigate, location],
  );

  const loginWithRedirect = useCallback(
    ({ targetUrl, locationState, options }: LoginOptions) =>
      auth0Client?.loginWithRedirect({
        ...options,
        authorizationParams: {
          redirect_uri: `${document.location.origin}/login/callback`,
          ...options?.authorizationParams,
        },
        appState: {
          locationState,
          targetUrl,
        },
      }),
    [auth0Client],
  );

  const handleRedirectCallback = useCallback(async () => {
    if (!auth0Client) return;
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      const { appState } = await auth0Client.handleRedirectCallback();
      const access_token = await auth0Client.getTokenSilently();
      setJwt(access_token);
      const authenticated = await auth0Client.isAuthenticated();
      setIsAuthenticated(authenticated);
      const id_token = await auth0Client.getIdTokenClaims();
      setIdTokenClaims(id_token ?? null);
      onRedirectCallback(appState);
      setAppState(appState);
    } else if (window.location.search.includes("success=true")) {
      await loginWithRedirect({ targetUrl: "/" });
    }
  }, [auth0Client, loginWithRedirect, onRedirectCallback]);

  useSentryTag("isAuthenticated", isAuthenticated);
  useTrackContext("is_authenticated", isAuthenticated);

  const context: IAuthContext = {
    isLoading,
    isInitialized,
    jwt,
    email: idTokenClaims?.email ?? null,
    isAdmin: getIsAdmin(idTokenClaims),
    isAuthenticated,
    appState,
    loginWithRedirect,
    logout: () => {
      if (!auth0Client) return;
      const { origin, pathname, search } = document.location;
      const query = new URLSearchParams({
        [RETURN_PATH_PARAM]: `${pathname}${search}`,
      });
      const returnTo = `${origin}/logout/callback?${query.toString()}`;
      auth0Client.logout({ logoutParams: { returnTo } });
    },
    handleRedirectCallback,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

function getIsAdmin(idTokenClaims: IdToken | null) {
  if (!idTokenClaims) return false;

  const roles: string[] | undefined = idTokenClaims["https://id.naf.no/roles"];
  if (!roles) return false;

  const adminRole = "VehicleContractAdmin";
  return roles.includes(adminRole);
}
