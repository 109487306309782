import { HTMLAttributes, ReactNode } from "react";
import styled, { css } from "styled-components";
import { InputComponent } from "./InputComponent";
import { DescriptionInfoButton } from "./InputDescription";

export interface LabeledInputComponentProps {
  id: string;
  label: ReactNode;
  description?: string;
  children: ReactNode;
  className?: string;
  hasError?: boolean;
  disabled?: boolean;
}

interface LabeledInputComponentWrapperProps
  extends HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
}

export const LabeledInputComponentWrapper = styled(function ({
  className,
  disabled,
  ...props
}: LabeledInputComponentWrapperProps) {
  return (
    <div
      {...props}
      className={[
        "LabeledInputComponent",
        disabled ? "LabeledInputComponent-disabled" : null,
        className,
      ]
        .filter((c) => !!c)
        .join(" ")}
    />
  );
})`
  textarea,
  input {
    font-variant-numeric: lining-nums;
  }

  .LabeledInputComponent-label-row {
    margin-bottom: ${(props) => props.theme.spacing.space16};

    & > label {
      font-weight: bold;
      margin-bottom: ${(props) => props.theme.spacing.space16};
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      color: ${({ theme }) => theme.themeLight.typography.disabledText};

      textarea,
      input[type="text"],
      input[type="search"],
      input[type="email"],
      input[type="url"],
      input[type="tel"],
      input[type="date"],
      input[type="number"],
      select {
        background: ${({ theme }) =>
          theme.themeLight.componentColors.inputElement.backgroundDisabled};
        cursor: not-allowed;
      }
    `}
`;

export function LabeledInputComponent({
  id,
  label,
  description,
  children,
  className,
  hasError,
  disabled,
}: LabeledInputComponentProps) {
  return (
    <LabeledInputComponentWrapper className={className} disabled={disabled}>
      <div className="LabeledInputComponent-label-row">
        <label htmlFor={id}>{label}</label>
        {description ? (
          <DescriptionInfoButton label={label} description={description} />
        ) : null}
      </div>
      <InputComponent
        className="LabeledInputComponent-children"
        hasError={hasError}
      >
        {children}
      </InputComponent>
    </LabeledInputComponentWrapper>
  );
}

export const LabeledCheckboxComponent = styled(function (
  props: LabeledInputComponentProps,
) {
  return <LabeledInputComponent {...props} />;
})`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .LabeledInputComponent-label-row {
    order: 1;
    flex: 1 1 0;
    margin-bottom: 0;
  }

  .InputDescription {
    order: 2;
    width: 100%;
  }

  .LabeledInputComponent-children {
    order: 0;
    margin-right: 1em;
    margin-top: 0;
  }
`;
