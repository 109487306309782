import { customizations } from "@naf/teamscheme-core";
import { ReactNode, Suspense, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { AppWrapper } from "../AppWrapper";
import Main from "../Main";
import { Auth0AuthProvider } from "../auth/AuthProvider";
import { AppLayout } from "../layout/AppLayout";
import LoadingBox from "../shared/LoadingBox";
import { useGTMInitializer } from "../tagManager/setupTagManager";
import { GlobalStyle } from "./GlobalStyle";
import { StandaloneHeader } from "../layout/header/StandaloneHeader";

// Placeholder until we can add /logout/callback to whitelist.
// Must wait for after summer
function useRedirectToLogoutCallback() {
  const search = typeof window !== "undefined" ? window.location.search : null;
  useEffect(() => {
    if (!search) return;
    const query = new URLSearchParams(window.location.search);
    const logout = typeof query.get("logout") === "string";
    if (logout) {
      query.delete("logout");
      window.location.href = `/logout/callback?${query.toString()}`;
    }
  }, [search]);
}

function LoadingRouteScreen() {
  return (
    <AppLayout>
      <Main>
        <StandaloneHeader />
        <LoadingBox />
      </Main>
    </AppLayout>
  );
}
export function PageShell({ children }: { children: ReactNode }) {
  useRedirectToLogoutCallback();
  useGTMInitializer();

  // TODO: React.StrictMode after migrating to @tanstack/react-query
  return (
    <>
      <Auth0AuthProvider>
        <ThemeProvider
          theme={{
            ...customizations,
            focus: { ...customizations.focus, color: "black" },
          }}
        >
          <GlobalStyle />
          <Suspense fallback={<LoadingRouteScreen />}>
            <AppWrapper>{children}</AppWrapper>
          </Suspense>
        </ThemeProvider>
      </Auth0AuthProvider>
    </>
  );
}
