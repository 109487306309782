import styled from "styled-components";

export const AppLayout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > footer > *:last-child {
    margin-bottom: 0;
  }
`;
