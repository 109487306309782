import { Outlet } from "react-router";
import { PageShell } from "../renderer/PageShell";
import { AppPages } from "./AppPages";
import { getRelativePath } from "./AppRoutes";
import { RouteObject, ScrollRestoration, redirect } from "react-router-dom";
import { adminRoutes } from "../admin/adminRoutes";
import { CopyFooter } from "../shared/elements/CopyFooter";
import { Suspense, lazy } from "react";
import { StaticLoadingScreen } from "../shared/LoadingScreen";

export const routes: RouteObject[] = [
  {
    path: getRelativePath(AppPages.LandingPage),
    element: (
      <PageShell>
        <Outlet />
        <ScrollRestoration />
      </PageShell>
    ),
    children: [
      adminRoutes,
      {
        element: (
          <Suspense fallback={<StaticLoadingScreen />}>
            <Outlet />
            <CopyFooter />
          </Suspense>
        ),
        children: [
          {
            path: "/t/:token",
            children: [
              {
                path: ":path",
                Component: lazy(() =>
                  import("./ShortContractUrlRoute").then((m) => ({
                    default: m.Component,
                  })),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import("./ShortContractUrlRoute").then((m) => ({
                    default: m.Component,
                  })),
                ),
              },
            ],
          },
          {
            path: "/i/:token",
            children: [
              {
                path: ":path",
                Component: lazy(() =>
                  import("./ShortInvitationRoute").then((m) => ({
                    default: m.Component,
                  })),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import("./ShortInvitationRoute").then((m) => ({
                    default: m.Component,
                  })),
                ),
              },
            ],
          },
          {
            path: getRelativePath(AppPages.Create),
            Component: lazy(() =>
              import("../contract/create-contract/CreateContractPage").then(
                (m) => ({ default: m.CreateContractPage }),
              ),
            ),
          },
          {
            path: getRelativePath(AppPages.AboutLandingPage),
            children: [
              {
                path: getRelativePath(AppPages.AboutDigitalSigning),
                Component: lazy(() =>
                  import("../about/DigitalSigningPage").then((m) => ({
                    default: m.DigitalSigningPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.AboutFaq),
                Component: lazy(() =>
                  import("../about/faq/FaqPage").then((m) => ({
                    default: m.FaqPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.AboutHowItWorks),
                Component: lazy(() =>
                  import("../about/HowItWorksPage").then((m) => ({
                    default: m.HowItWorksPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.AboutOwnershipTransfer),
                Component: lazy(() =>
                  import("../about/AboutOwnershipTransferPage").then((m) => ({
                    default: m.AboutOwnershipTransferPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.AboutVipps),
                Component: lazy(() =>
                  import("../about/AboutVippsPage").then((m) => ({
                    default: m.AboutVippsPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.AboutInstructions),
                Component: lazy(() =>
                  import("../about/InstructionsPage").then((m) => ({
                    default: m.InstructionsPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.AboutPrivacy),
                Component: lazy(() =>
                  import("../about/PrivacyPage").then((m) => ({
                    default: m.PrivacyPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.AboutStaticContracts),
                Component: lazy(() =>
                  import("../about/StaticContractsPage").then((m) => ({
                    default: m.StaticContractsPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.AboutTerms),
                Component: lazy(() =>
                  import("../about/TermsPage").then((m) => ({
                    default: m.TermsPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.AboutUpgrade),
                Component: lazy(() =>
                  import("../about/AboutUpgradePage").then((m) => ({
                    default: m.AboutUpgradePage,
                  })),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import("../about/AboutLandingPage").then((m) => ({
                    default: m.AboutLandingPage,
                  })),
                ),
              },
            ],
          },
          {
            path: getRelativePath(AppPages.LandingPageMc),
            Component: lazy(() =>
              import("../landing/pages/McLandingPage").then((m) => ({
                default: m.McLandingPage,
              })),
            ),
          },
          {
            path: getRelativePath(AppPages.LandingPageCaravan),
            Component: lazy(() =>
              import("../landing/pages/CaravanLandingPage").then((m) => ({
                default: m.CaravanLandingPage,
              })),
            ),
          },
          {
            path: getRelativePath(AppPages.BecomeMember),
            Component: lazy(() =>
              import("../membership/BecomeMemberWrapperPage").then((m) => ({
                default: m.BecomeMemberWrapperPage,
              })),
            ),
            children: [
              {
                path: "callback/:stateId",
                Component: lazy(() =>
                  import("../membership/MembershipPaymentPage").then((m) => ({
                    default: m.MembershipPaymentPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.BecomeMemberWelcome),
                Component: lazy(() =>
                  import("../membership/BecomeMemberWelcomePage").then((m) => ({
                    default: m.BecomeMemberWelcomePage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.BecomeMemberForm),
                Component: lazy(() =>
                  import("../membership/BecomeMemberFormPage").then((m) => ({
                    default: m.BecomeMemberFormPage,
                  })),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import("../membership/BecomeMemberPage").then((m) => ({
                    default: m.BecomeMemberPage,
                  })),
                ),
              },
            ],
          },
          {
            path: "signering/:token",
            children: [
              {
                path: "callback",
                Component: lazy(() =>
                  import(
                    "../contract/signing-task/SigningTaskCallbackPage"
                  ).then((m) => ({ default: m.SigningTaskCallbackPage })),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import("../contract/signing-task/SigningTaskPage").then(
                    (m) => ({ default: m.SigningTaskPage }),
                  ),
                ),
              },
            ],
          },
          {
            path: "sign/oidc-callback",
            Component: lazy(() =>
              import("../contract/signing/OidcSignCallbackPage").then((m) => ({
                default: m.OidcSignCallbackPage,
              })),
            ),
          },
          {
            path: "login/callback",
            Component: lazy(() =>
              import("../auth/LoginCallbackPage").then((m) => ({
                default: m.LoginCallbackPage,
              })),
            ),
          },
          {
            path: "logout/callback",
            Component: lazy(() =>
              import("../auth/LogoutCallbackPage").then((m) => ({
                default: m.LogoutCallbackPage,
              })),
            ),
          },
          {
            path: "payment",
            Component: lazy(() =>
              import("../about/StaticPaymentLandingPage").then((m) => ({
                default: m.StaticPaymentLandingPage,
              })),
            ),
          },
          /* TODO: AppPages */
          {
            path: "betaling/:paymentId/:signature",
            Component: lazy(() =>
              import("../payments/PaymentPage").then((m) => ({
                default: m.PaymentPage,
              })),
            ),
          },
          {
            path: "arkiv",
            Component: lazy(() =>
              import("../shared/error/ArchivedContractScreen").then((m) => ({
                default: m.ArchivedContractScreen,
              })),
            ),
          },
          {
            path: "karantene",
            Component: lazy(() =>
              import("../shared/error/QuarantinedContractScreen").then((m) => ({
                default: m.QuarantinedContractScreen,
              })),
            ),
          },
          {
            path: getRelativePath(AppPages.Invitation),
            Component: lazy(() =>
              import("../contract/invitation/InvitationLandingPage").then(
                (m) => ({
                  default: m.InvitationLandingPage,
                }),
              ),
            ),
          },
          {
            path: getRelativePath(AppPages.Contract),
            Component: lazy(() =>
              import("../contract/routing/ContractRoutes").then((m) => ({
                default: m.ContractRoutes,
              })),
            ),
            children: [
              {
                path: getRelativePath(AppPages.ContractLoginCallback),
                Component: lazy(() =>
                  import(
                    "../contract/contract-type/ContractLoginCallbackPage"
                  ).then((m) => ({ default: m.ContractLoginCallbackPage })),
                ),
              },
              {
                path: "lagre",
                Component: lazy(() =>
                  import("../contract/save-contract/SaveContractPage").then(
                    (m) => ({ default: m.SaveContractPage }),
                  ),
                ),
              },
              {
                path: getRelativePath(AppPages.ContractForm),
                Component: lazy(() =>
                  import("../contract/form/routing/ContractFormRoutes").then(
                    (m) => ({ default: m.ContractFormRoutes }),
                  ),
                ),
                children: [
                  {
                    path: getRelativePath(AppPages.ContractFormAboutVehicle),
                    Component: lazy(() =>
                      import("../contract/form/fields/AboutVehiclePage").then(
                        (m) => ({ default: m.AboutVehiclePage }),
                      ),
                    ),
                  },
                  {
                    path: getRelativePath(AppPages.ContractFormContactInfo),
                    Component: lazy(() =>
                      import(
                        "../contract/form/contact-info/ContactInfoPage"
                      ).then((m) => ({ default: m.ContactInfoPage })),
                    ),
                  },
                  {
                    path: getRelativePath(AppPages.ContractFormAboutPayment),
                    Component: lazy(() =>
                      import("../contract/form/fields/AboutPaymentPage").then(
                        (m) => ({ default: m.AboutPaymentPage }),
                      ),
                    ),
                  },
                  {
                    path: getRelativePath(AppPages.ContractFormInsurance),
                    Component: lazy(() =>
                      import("../contract/form/insurance/InsurancePage").then(
                        (m) => ({ default: m.InsurancePage }),
                      ),
                    ),
                  },
                  {
                    path: getRelativePath(AppPages.ContractFormAttachments),
                    Component: lazy(() =>
                      import(
                        "../contract/form/attachments/AttachmentsPage"
                      ).then((m) => ({ default: m.AttachmentsPage })),
                    ),
                  },
                  {
                    path: getRelativePath(AppPages.ContractFormType),
                    Component: lazy(() =>
                      import(
                        "../contract/form/select-type/ContractTypePage"
                      ).then((m) => ({ default: m.ContractTypePage })),
                    ),
                  },
                  {
                    index: true,
                    loader: (x) => {
                      const nextPath = getRelativePath(
                        AppPages.ContractFormAboutVehicle,
                      );
                      const nextSearch = new URL(
                        x.request.url,
                      ).searchParams.toString();
                      const nextLocation = `${nextPath}?${nextSearch}`;
                      return redirect(nextLocation);
                    },
                  },
                ],
              },
              {
                path: getRelativePath(AppPages.ContractMembershipUpgrade),
                Component: lazy(() =>
                  import(
                    "../contract/contract-type/membership-upgrade/MembershipUpgradePage"
                  ).then((m) => ({ default: m.MembershipUpgradePage })),
                ),
              },
              {
                path: getRelativePath(AppPages.ContractPreview),
                Component: lazy(() =>
                  import("../contract/preview/PreviewPage").then((m) => ({
                    default: m.PreviewPage,
                  })),
                ),
              },
              {
                path: "bankid/oppgrader-kontrakt",
                Component: lazy(() =>
                  import(
                    "../contract/contract-type/SigningMethodUpgradeContractPage"
                  ).then((m) => ({
                    default: m.SigningMethodUpgradeContractPage,
                  })),
                ),
              },
              {
                path: "signer/:hash",
                children: [
                  {
                    path: getRelativePath(AppPages.ContractSignCallback),
                    Component: lazy(() =>
                      import("../contract/signing/SigningCallbackPage").then(
                        (m) => ({ default: m.SigningCallbackPage }),
                      ),
                    ),
                  },
                  {
                    index: true,
                    Component: lazy(() =>
                      import("../contract/signing/SigningPage").then((m) => ({
                        default: m.SigningPage,
                      })),
                    ),
                  },
                ],
              },
              {
                path: getRelativePath(AppPages.ContractPremiumRoot),
                Component: lazy(() =>
                  import(
                    "../contract/contract-type/trial-membership/UpgradedContractInfoRoutesWrapper"
                  ).then((m) => ({
                    default: m.UpgradedContractInfoRoutesWrapper,
                  })),
                ),
                children: [
                  {
                    path: "provemedlemskap",
                    Component: lazy(() =>
                      import(
                        "../contract/contract-type/trial-membership/TrialMembershipRoutesWrapper"
                      ).then((m) => ({
                        default: m.TrialMembershipRoutesWrapper,
                      })),
                    ),
                    children: [
                      {
                        path: ":orchestrationId",
                        Component: lazy(() =>
                          import(
                            "../contract/contract-type/trial-membership/ActivateTrialMembershipOrchestrationPage"
                          ).then((m) => ({
                            default: m.ActivateTrialMembershipOrchestrationPage,
                          })),
                        ),
                      },
                      {
                        index: true,
                        Component: lazy(() =>
                          import(
                            "../contract/contract-type/trial-membership/ActivateTrialMembershipPage"
                          ).then((m) => ({
                            default: m.ActivateTrialMembershipPage,
                          })),
                        ),
                      },
                    ],
                  },
                  {
                    index: true,
                    Component: lazy(() =>
                      import(
                        "../contract/contract-type/trial-membership/UpgradedContractInfoPage"
                      ).then((m) => ({ default: m.UpgradedContractInfoPage })),
                    ),
                  },
                ],
              },
              {
                path: getRelativePath(AppPages.ContractUpgradeProductRoot),
                Component: lazy(() =>
                  import(
                    "../contract/contract-type/contract-upgrade/ContractUpgradePaymentRoot"
                  ).then((m) => ({ default: m.ContractUpgradePaymentRoot })),
                ),
                children: [
                  {
                    index: true,
                    Component: lazy(() =>
                      import(
                        "../contract/contract-type/contract-upgrade/CreatePaymentPage"
                      ).then((m) => ({ default: m.CreatePaymentPage })),
                    ),
                  },
                ],
              },
              {
                path: getRelativePath(AppPages.ContractStatus),
                Component: lazy(() =>
                  import("../contract/status/StatusPage").then((m) => ({
                    default: m.StatusPage,
                  })),
                ),
              },
              {
                path: getRelativePath(AppPages.ContractConfirmationLanding),
                Component: lazy(() =>
                  import(
                    "../contract/confirmation/ConfirmationLandingPageWrapper"
                  ).then((m) => ({
                    default: m.ConfirmationLandingPageWrapper,
                  })),
                ),
                children: [
                  {
                    index: true,
                    Component: lazy(() =>
                      import(
                        "../contract/confirmation/ConfirmationLandingPageRedirecter"
                      ).then((m) => ({
                        default: m.ConfirmationLandingPageRedirecter,
                      })),
                    ),
                  },
                  {
                    path: getRelativePath(
                      AppPages.ContractConfirmationWaitingForPdf,
                    ),
                    Component: lazy(() =>
                      import("../contract/confirmation/WaitingForPdfPage").then(
                        (m) => ({ default: m.WaitingForPdfPage }),
                      ),
                    ),
                  },
                  {
                    path: getRelativePath(
                      AppPages.ContractConfirmationSettlement,
                    ),
                    Component: lazy(() =>
                      import(
                        "../contract/confirmation/settlement/SettlementPage"
                      ).then((m) => ({ default: m.SettlementPage })),
                    ),
                  },
                  {
                    path: getRelativePath(AppPages.ContractConfirmationSummary),
                    Component: lazy(() =>
                      import(
                        "../contract/confirmation/summary/SummaryPage"
                      ).then((m) => ({ default: m.SummaryPage })),
                    ),
                  },
                  {
                    path: getRelativePath(
                      AppPages.ContractConfirmationInsurance,
                    ),
                    Component: lazy(() =>
                      import(
                        "../contract/confirmation/PostConfirmationCarInsurancePage"
                      ).then((m) => ({
                        default: m.PostConfirmationCarInsurancePage,
                      })),
                    ),
                  },
                  {
                    path: getRelativePath(
                      AppPages.ContractConfirmationFinancial,
                    ),
                    Component: lazy(() =>
                      import("../contract/confirmation/FinancialPage").then(
                        (m) => ({ default: m.FinancialPage }),
                      ),
                    ),
                  },
                  {
                    path: getRelativePath(
                      AppPages.ContractConfirmationTrialMembership,
                    ),
                    children: [
                      {
                        path: getRelativePath(
                          AppPages.ContractConfirmationTrialMembershipOrchestration,
                        ),
                        Component: lazy(() =>
                          import(
                            "../contract/confirmation/trial-membership/PostConfirmationTrialMembershipOrchestrationPage"
                          ).then((m) => ({
                            default:
                              m.PostConfirmationTrialMembershipOrchestrationPage,
                          })),
                        ),
                      },
                      {
                        index: true,
                        Component: lazy(() =>
                          import(
                            "../contract/confirmation/trial-membership/PostConfirmationActivateTrialMembershipPage"
                          ).then((m) => ({
                            default:
                              m.PostConfirmationActivateTrialMembershipPage,
                          })),
                        ),
                      },
                    ],
                  },
                ],
              },
              {
                path: getRelativePath(AppPages.ContractInvitation),
                Component: lazy(() =>
                  import("../contract/invitation/RedirectToInvitation").then(
                    (m) => ({ default: m.RedirectToInvitation }),
                  ),
                ),
              },
              {
                index: true,
                Component: lazy(() =>
                  import(
                    "../contract/contract-landing/ContractLandingPage"
                  ).then((m) => ({ default: m.ContractLandingPage })),
                ),
              },
            ],
          },
          {
            path: "*",
            Component: lazy(() =>
              import("../shared/error/NotFoundScreen").then((m) => ({
                default: m.NotFoundScreen,
              })),
            ),
          },
          {
            index: true,
            Component: lazy(() =>
              import("../landing/pages/DefaultLandingPage").then((m) => ({
                default: m.DefaultLandingPage,
              })),
            ),
          },
        ],
      },
    ],
  },
];
