import { ButtonLink } from "@naf/teamscheme";
import { LoadingText } from "@naf/teamscheme";
import { onDesktop } from "@naf/teamscheme-core";
import React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { useAuth } from "../../auth/AuthProvider";
import { useCustomer } from "../../auth/CustomerProvider";
import { CreateSalesContractRequest } from "./CreateContract";
import CreateContractForm from "./CreateContractForm";
import Card from "../../formatting/Card";
import { PageContent } from "../../formatting/PageContent";
import BankIdSigningLogo from "../../shared/logos/BankIdSigningLogo";
import SavedContracts, {
  usePublicContracts,
} from "../../landing/SavedContracts";

export const Separator = styled.hr`
  width: ${({ theme }) => theme.spacing.space120};
  height: ${({ theme }) => theme.spacing.space4};
  background: ${({ theme }) => theme.nafColor.signature.yellow};
  border: none;
  display: block;
  margin: ${({ theme }) => theme.spacing.space4} 0px
    ${({ theme }) => theme.spacing.space48};
`;

export interface CreateContractCardProps {
  bodyRef?: React.MutableRefObject<HTMLDivElement | null>;
  createCardRef?: React.MutableRefObject<HTMLDivElement | null>;
  lpnInputRef?: React.MutableRefObject<HTMLInputElement | null>;
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  create(request: CreateSalesContractRequest): Promise<void>;
  className?: string;
  title: string;
  description: string;
}

export const SignedWithBankIdStyled = styled(BankIdSigningLogo)`
  max-width: 15em;
  height: auto;
`;

const CreateContractCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  width: 100%;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.nafColor.signature.white};
`;

const CreateContractCardBody = styled.div`
  flex: 1 0 auto;

  ${onDesktop`
    display: flex;
    & > * {
      flex: 1;
    }
  `}
`;

const StyledCard = styled(Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex: 0 0 30em;
  padding: 0;

  h1 {
    margin-top: 0;
  }
`;

export default function CreateContractCard({
  bodyRef,
  createCardRef,
  showForm,
  setShowForm,
  create,
  lpnInputRef,
  className,
  title,
  description,
}: CreateContractCardProps) {
  const publicContracts = usePublicContracts();
  const hasPublicContracts = !!publicContracts.result?.items.length;
  const auth = useAuth();
  const customer = useCustomer();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}`;

  const getStartedContractText = (
    <p>Eller hent en påbegynt kontrakt lagret på Mitt NAF:</p>
  );

  return (
    <CreateContractCardWrapper className={className}>
      <PageContent>
        <CreateContractCardBody ref={bodyRef}>
          <StyledCard ref={createCardRef}>
            <h1>{title}</h1>
            <p>{description}</p>
            <Separator />
            <div>
              {publicContracts.isLoading && !publicContracts.result ? (
                <LoadingText />
              ) : hasPublicContracts && !showForm ? (
                <SavedContracts
                  showStartNew={hasPublicContracts}
                  publicContracts={publicContracts}
                  onStartNew={() => {
                    setShowForm(true);
                  }}
                />
              ) : (
                <>
                  <CreateContractForm
                    onSubmit={create}
                    inputRef={lpnInputRef}
                  />
                  {!customer.isLoggedIn ? (
                    <>
                      {getStartedContractText}
                      <ButtonLink
                        variant="primary"
                        onClick={() => {
                          auth.loginWithRedirect({
                            targetUrl: currentUrl,
                          });
                        }}
                      >
                        Se kontrakter fra Mitt NAF
                      </ButtonLink>
                    </>
                  ) : hasPublicContracts ? (
                    <>
                      {getStartedContractText}
                      <ButtonLink
                        variant="primary"
                        onClick={() => {
                          setShowForm(false);
                        }}
                      >
                        Se kontrakter fra Mitt NAF
                      </ButtonLink>
                    </>
                  ) : null}
                </>
              )}
            </div>
          </StyledCard>
        </CreateContractCardBody>
      </PageContent>
    </CreateContractCardWrapper>
  );
}
