import { ReactNode, useState } from "react";
import { AppLayout } from "./AppLayout";
import LayoutContext from "./LayoutContext";

export default function Layout({ children }: { children: ReactNode }) {
  const [paddingBottom, setPaddingBottom] = useState(0);
  return (
    <LayoutContext.Provider value={{ setPaddingBottom }}>
      <AppLayout style={{ paddingBottom: `${paddingBottom}px` }}>
        {children}
      </AppLayout>
    </LayoutContext.Provider>
  );
}
