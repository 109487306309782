import React, { ReactNode, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Role } from "#vehicle-contract/common/lib/model/contract/Role";
import { ContractType } from "#vehicle-contract/common/lib/model/contract/SalesContract";
import { useTracking } from "../../Tracking";
import { useApiClient } from "../../api/ApiClient";
import { getRelativeUrlPart } from "../../utils";
import { ContractViewModel } from "../model/ContractViewModel";
import { CustomVehicleDataFields } from "./CustomVehicleData";

export interface CreateSalesContractResponse {
  url: string;
  contract: ContractViewModel;
}

export interface CreateSalesContractRequest {
  role: Role;
  licensePlateNumber?: string;
  finnId?: string;
  customVehicleData?: CustomVehicleDataFields;
  contractType?: ContractType;
}

export interface ICreateContractContext {
  create(request: CreateSalesContractRequest): Promise<void>;
}

export const CreateContractContext =
  React.createContext<ICreateContractContext | null>(null);

export function useCreateContractContext() {
  const createContractContext = useContext(CreateContractContext);
  if (!createContractContext) {
    throw new Error("No CreateContractContext found!");
  }
  return createContractContext;
}

export function CreateContractProvider({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const client = useApiClient();
  const tracking = useTracking();

  async function create(request: CreateSalesContractRequest) {
    const { role } = request;
    const response = await client.send<CreateSalesContractResponse>(
      "POST",
      "contract",
      request,
    );
    const { contract, url } = response;
    tracking.contractLoad(contract, role);
    navigate(getRelativeUrlPart(new URL(url)));
  }
  return (
    <CreateContractContext.Provider value={{ create }}>
      {children}
    </CreateContractContext.Provider>
  );
}
