import React, { useEffect, useState, ReactNode } from "react";
import { useLocation } from "react-router";

export interface IAppHistoryContext {
  appHistory: unknown[];
}

export const AppHistoryContext = React.createContext<IAppHistoryContext | null>(
  null,
);

export function AppHistoryProvider({ children }: { children: ReactNode }) {
  const location = useLocation();
  const [appHistory, setAppHistory] = useState<unknown[]>([]);

  useEffect(() => {
    setAppHistory((appHistory) => [...appHistory, location]);
  }, [location, location.pathname]);

  return (
    <AppHistoryContext.Provider value={{ appHistory }}>
      {children}
    </AppHistoryContext.Provider>
  );
}
