import { LoadingText } from "@naf/teamscheme";
import { TextLink } from "@naf/teamscheme";
import { captureException } from "@sentry/core";
import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";
import { DateString } from "#vehicle-contract/common/lib/model/common/DateString";
import { type ApiError, useApiClient } from "../api/ApiClient";
import { VehicleModel } from "../contract/form/fields/VehicleModel";
import { FinnNotFoundErrorMessage } from "../contract/form/fields/finn/AddDataFromFinn";
import { AutoTechVehicleViewModel } from "../contract/model/ContractViewModel";
import ExternalTextLink from "../shared/ExternalTextLink";
import ErrorMessage from "../shared/error/ErrorMessage";
import { Cancelable, makeCancelable } from "../utils";

export interface VehicleSearchState {
  result: VehicleSearchResult;
  error: string | null;
}

export interface VehicleSearchProps {
  placeholder: ReactNode;
  licensePlateNumber?: string;
  finnId?: string;
  onChange: Dispatch<SetStateAction<VehicleSearchState | null>>;
}

export interface FinnEntryViewModel {
  isPassengerCar: boolean;
  registeredDate?: DateString;
  year?: number;
  colorName?: string;
  identifier?: string;
  licensePlateNumber?: string;
  manufactureName?: string;
  modelName?: string;
  url?: string;
}

export interface VehicleSearchResult {
  finnEntry?: FinnEntryViewModel;
  autoTechData: AutoTechVehicleViewModel;
}

export default function VehicleSearch({
  placeholder,
  licensePlateNumber,
  finnId,
  onChange,
}: VehicleSearchProps) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<VehicleSearchResult | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [retry, setRetry] = useState(0);

  const client = useApiClient();

  function validateResponse(result: VehicleSearchResult) {
    const error =
      !result || !result.autoTechData
        ? "Fant ingen kjøretøy på regnummeret. Sørg for at reg.nr er korrekt."
        : null;

    return error;
  }

  useEffect(() => {
    async function searchByLicensePlateNumber(
      searched: string,
      promise: Promise<VehicleSearchResult>,
    ) {
      setLoading(true);

      try {
        const result = await promise;

        if (searched === licensePlateNumber) {
          const error = validateResponse(result);
          onChange({ result, error });
          setResult(result);
          setError(error);
        }
        setLoading(false);
      } catch (e) {
        const error = e as { isCanceled: true } | ApiError;
        if (!("isCanceled" in error && error.isCanceled)) {
          console.log(
            `Failed to search vehicle by license plate number ${searched}.`,
            error,
          );
          setError("Kunne ikke søke opp regnummeret.");
          setLoading(false);
          captureException(error);
        }
      }
    }

    function fetchVehicleByLicensePlateNumber(searched: string) {
      return client.get<VehicleSearchResult>(
        `vehicle?licensePlateNumber=${searched}`,
      );
    }

    if (retry < 0) return;

    let cancelable: Cancelable<VehicleSearchResult>;
    const timeout = window.setTimeout(() => {
      if (licensePlateNumber) {
        cancelable = makeCancelable(
          fetchVehicleByLicensePlateNumber(licensePlateNumber),
        );

        searchByLicensePlateNumber(licensePlateNumber, cancelable.promise);
      }
    }, 500);

    return () => {
      clearTimeout(timeout);

      if (cancelable) cancelable.cancel();
    };
  }, [licensePlateNumber, client, onChange, retry]);

  useEffect(() => {
    function fetchVehicleByFinnId(finnId: string) {
      return client.get<VehicleSearchResult>(`vehicle?finnId=${finnId}`);
    }

    async function searchByFinnId(
      searched: string,
      promise: Promise<VehicleSearchResult>,
    ) {
      setLoading(true);

      try {
        const result = await promise;

        const error =
          !result || !result.finnEntry
            ? FinnNotFoundErrorMessage
            : validateResponse(result);
        onChange({ result, error });
        setResult(result);
        setError(error);
        setLoading(false);
      } catch (e) {
        const error = e as { isCanceled: true } | ApiError;
        if (!("isCanceled" in error && error.isCanceled)) {
          console.log(`Failed to search vehicle by finn ID ${searched}`, error);
          setError("Kunne ikke søke opp FINN-koden.");
          setLoading(false);
          captureException(error);
        }
      }
    }

    // TODO: just doing this to satisfy biome. TODO: use a better subsdription pattern
    if (retry < 0) return;

    if (finnId) {
      const cancelable = makeCancelable(fetchVehicleByFinnId(finnId));

      searchByFinnId(finnId, cancelable.promise);

      return () => cancelable.cancel();
    }
  }, [finnId, client, onChange, retry]);

  return (
    <div className="VehicleSearch">
      {loading ? <LoadingText text="Henter kjøretøy" /> : null}
      {!loading && !error && result && (
        <span>
          {result.autoTechData.vehicleTypeShortName
            ? `${result.autoTechData.vehicleTypeShortName}: `
            : null}
          <VehicleModel autoTechData={result.autoTechData} />
          {result.finnEntry ? (
            <span> ({result.autoTechData.licensePlateNumber})</span>
          ) : null}
          <br />
          {result.finnEntry?.url ? (
            <ExternalTextLink href={result.finnEntry.url}>
              FINN.no-annonse
            </ExternalTextLink>
          ) : null}
        </span>
      )}
      {!loading && error ? (
        <ErrorMessage>
          <span>{error}</span>{" "}
          <TextLink
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              setRetry((i) => i + 1);
            }}
          >
            Prøv igjen
          </TextLink>
        </ErrorMessage>
      ) : null}
      {!loading && !error && !result ? (
        <Placeholder>{placeholder}</Placeholder>
      ) : null}
    </div>
  );
}

const Placeholder = styled.div`
  min-height: ${({ theme }) => theme.fontStyle.bodyText.bodyText["line-height"]};
`;
