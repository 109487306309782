import { MenuListItem } from "@naf/teamscheme";
import { LocationDescriptor } from "@naf/teamscheme";
import { AccountCircle } from "@styled-icons/material/AccountCircle";
import { AccountCircle as AccountCircleOutlined } from "@styled-icons/material-outlined/AccountCircle";
import { Lock } from "@styled-icons/material-outlined/Lock";
import { LockOpen } from "@styled-icons/material/LockOpen";
import { Logout } from "@styled-icons/material/Logout";
import { useMemo } from "react";
import { useLocation } from "react-router";
import { EVENTS, externalLinkEvent } from "../../Tracking";
import { IAuthContext, useAuth } from "../../auth/AuthProvider";
import { useCustomer } from "../../auth/CustomerProvider";
import { NAF_NO_MITT_NAF_URL } from "../../shared/NafnoUrls";
import {
  getLocationState,
  getLocationString,
} from "../../shared/utils/Location";
import { MaybeOrderedMenuListItem } from "./HeaderObjects";

function createLoggedInHeaderProps(): MaybeOrderedMenuListItem {
  const props: MenuListItem = {
    key: "MittNAF",
    text: "Mitt NAF",
    icon: {
      icon: <AccountCircleOutlined size={24} />,
      hoverIcon: <AccountCircle size={24} />,
    },
    href: NAF_NO_MITT_NAF_URL,
    trackEvent: externalLinkEvent(NAF_NO_MITT_NAF_URL),
  };
  return props;
}

function createLoginHeaderProps(
  auth: IAuthContext,
  targetLocation: LocationDescriptor,
): MaybeOrderedMenuListItem {
  const text = "Logg inn";
  const props: MenuListItem = {
    key: "LoggInn",
    text,
    icon: {
      icon: <Lock size={24} />,
      hoverIcon: <LockOpen size={24} />,
    },
    trackEvent: { ...EVENTS.HEADER.LOG_IN, label: text },
    onClick: () => {
      auth.loginWithRedirect({
        targetUrl: getLocationString(targetLocation),
        locationState: getLocationState(targetLocation),
      });
    },
  };
  return props;
}

function createLogoutHeaderProps(auth: IAuthContext): MaybeOrderedMenuListItem {
  const text = "Logg ut";
  const props: MaybeOrderedMenuListItem = {
    key: "LoggUt",
    text,
    trackEvent: { ...EVENTS.HEADER.LOG_OUT, label: text },
    icon: {
      icon: <Logout size={24} />,
    },
    onClick: () => auth.logout(),
    order: 1,
  };
  return props;
}

export function useAuthHeaderElements(loginLocation?: LocationDescriptor) {
  const auth = useAuth();
  const customer = useCustomer();
  const location = useLocation();
  const loginTargetLocation = loginLocation || location;

  return useMemo(() => {
    if (customer.isLoading) {
      const loading: MenuListItem = {
        key: "HenterBruker",
        text: "Henter bruker",
        isLoading: true,
      };
      return [loading];
    }
    if (customer.result && customer.isLoggedIn) {
      const loggedIn = createLoggedInHeaderProps();
      const logout = createLogoutHeaderProps(auth);
      return [loggedIn, logout];
    }
    if (customer.isLoggedIn) {
      return [createLogoutHeaderProps(auth)];
    }
    return [createLoginHeaderProps(auth, loginTargetLocation)];
  }, [
    auth,
    customer.isLoading,
    customer.isLoggedIn,
    customer.result,
    loginTargetLocation,
  ]);
}
