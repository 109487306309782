import { useEffect } from "react";
import { setCookie } from "./cookies";

export function useGTMInitializer() {
  const gtmMeasurementId = "G-24VN2RDJN9";

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer.gtag = (...params) => {
        if (typeof window.dataLayer !== "undefined") {
          window.dataLayer.push(...params);
        }
      };
      if (typeof window.gtag !== "undefined") {
        window.gtag("get", gtmMeasurementId, "session_id", (sessionId) => {
          setCookie("ga_session_id", sessionId, 365, "None", true, ".naf.no");
        });
      }
    }
  }, []);
}
