import { SchemaType } from "./SchemaType";
import { VehicleData } from "./VehicleData";
import { Role } from "./Role";
import { DateString } from "../common/DateString";
import { SigningParty } from "./SigningParty";
import { ContractSource } from "./ContractSource";
import { CoOwner } from "./CoOwner";
import { SignatureInfo } from "./SignatureInfo";
import { ContractingParty, Seller } from "./ContractingParty";
import { CarInsurance } from "../insurance/CarInsurance";
import { CustomerReference } from "./CustomerReference";
import { AutoTechVehicle } from "./AutoTechVehicle";
import { FieldValue } from "./Field";
import { SettlementStatusInfo } from "./Settlement";
import { TrialMembershipOrchestrationHistoryItem } from "./TrialMembership";

export enum TransactionMethod {
  None = 0,
  BankTransfer = 1,
  Loan = 2,
  Vipps = 4,
  Cash = 8,
  Other = 16,
}

export interface TransactionInfo {
  method?: TransactionMethod;
  comment?: string;
  loan?: boolean;
  loanAmount?: number;
  downPayment?: number;
  settlementAmount?: number;
}

export interface SettlementFields {
  calculatedReRegistrationFee?: number;
  reRegistrationFee?: number;
  exemptedFromReRegistrationFee?: boolean;
  ownershipTransferDate?: DateString;
  price?: number;
}

export interface AttachmentFile {
  id: string;
  name: string;
  contentType: string;
  contentLength: number;
  created: string;
}

export interface AttachmentsInfo {
  files: AttachmentFile[];
  prepared?: AttachmentFile;
}

export interface SignicatPackage {
  packageId: string;
  archivedDocumentDeleted?: DateString;
}

export interface SignicatReference {
  useArchiveService: boolean;
}

export enum SignatureStatus {
  None = 0,
  Signed = 1,
  Declined = 2,
}

export interface SigningTask {
  role: Role;
  signingParty: SigningParty;
  signatureStatus: SignatureStatus;
  token: string;
  created: DateString;
  updated?: DateString;
}

export enum InvitationType {
  Sms = 1,
  Email = 2,
}

export interface InvitationHistoryItem {
  type: InvitationType;
  phone?: string;
  email?: string;
  sent: DateString;
  token?: string;
}

export interface Invitation {
  recipientToken: string;
  recipient: Role;
  recipientPhone?: string;
  recipientEmail?: string;
  type: InvitationType;
  senderPhone: string;
  accepted?: DateString;
  created: DateString;
  updated: DateString;
  sent?: DateString;
  attemptCount: number;
  history: InvitationHistoryItem[];
}

export interface S3Location {
  bucket: string;
  key: string;
}

export enum DocumentSignatureSectionType {
  Manual = 0,
  Digital = 1,
}

export interface DocumentInfo {
  hash: string;
  pdfLocation: S3Location;
  previewLocation?: S3Location;
  signatureSectionType: DocumentSignatureSectionType;
  created: DateString;
}

export enum ContractType {
  None = 0,
  Simple = 1,
  Standard = 2,
  Member = 4,
  Premium = 8,
}

export interface ContractTypeInfo {
  isRequired: boolean;
  current: ContractType;
  created?: DateString;
  updated?: DateString;
  upgradeLock?: DateString;
  payment?: ContractUpgradePayment;
  history: ContractTypeSelection[];
}

export interface ContractTypeSelection {
  contractType: ContractType;
  created: DateString;
  role?: Role;
  customerGuid?: string;
  customerId?: string;
  paymentId?: string;
}

export interface ContractUpgradePayment {
  created: DateString;
  updated: DateString;
  initiator: Role;
  customer: CustomerReference;
  contractType: ContractType;
  paymentId: string;
  isPaid: boolean;
  isRefunded: boolean;
}

export interface BankIdPayment {
  isPaid: boolean;
  updated: DateString;
  created: DateString;
}

export interface Party {
  actorOrganizationalOrPersonalIdentity: string;
  signerPersonalIdentity: string;
}

export interface ValidateResponse {
  document?: S3Location;
  violations: Violation[];
}

export interface Violation {
  code: string;
  description?: string;
}

export interface GenericResponse {
  entity?: unknown;
  violations?: Violation[];
  url: string;
}

export enum OwnershipTransferStep {
  None = 0,
  ValidationViolation = 1,
  ValidationError = 2,
  ValidationComplete = 3,
  TransferViolation = 4,
  TransferError = 5,
  TransferComplete = 6,
  RegistrationViolation = 7,
  RegistrationError = 8,
  RegistrationComplete = 9,
}
export interface OwnershipTransfer {
  licensePlateNumber: string;
  owner: Party;
  newOwner: Party;
  coOwner?: Party;
  newCoOwner?: Party;
}

export interface OwnershipTransferPreconditions {
  missingSeller: boolean;
  missingBuyer: boolean;
  invalidSeller: boolean;
  invalidBuyer: boolean;
  invalidBuyerCoOwner: boolean;
  invalidSellerCoOwner: boolean;
  missingLicensePlateNumber: boolean;
  errors: ContractValidationError[];
}

export interface ContractValidationError {
  role: Role;
  section: string;
  field: string;
  isRequired: boolean;
  patternError?: string;
}

export interface OwnershipTransferStatus {
  sellerEnabled: boolean;
  buyerEnabled: boolean;
}

export interface OwnershipTransferInfo {
  parameters?: OwnershipTransfer;
  preconditions?: OwnershipTransferPreconditions;
  validate?: SvvCall<ValidateResponse>;
  transfer?: SvvCall<GenericResponse>;
  createRegistration?: SvvCall<GenericResponse>;
  created: DateString;
  updated: DateString;
  expiration?: DateString;
  anonymized?: DateString;
  step: OwnershipTransferStep;
  stepUpdated: DateString;
}

export interface SvvCall<T> {
  fetched: DateString;
  error?: SvvErrorResponse;
  response?: T;
  responseReceived?: DateString;
}

export interface SvvErrorResponse {
  created: DateString;
  message: string;
}

export interface SalesContract extends SalesContractWithSignature {
  buyer?: ContractingParty;
  seller?: Seller;
  buyerCoOwner?: CoOwner;
  sellerCoOwner?: CoOwner;
  vehicleData?: VehicleData;
  document?: DocumentInfo;
  invitation?: Invitation;
  contractType?: ContractTypeInfo;
  bankIdPayment?: BankIdPayment;
  ownershipTransfer?: OwnershipTransferInfo;
  buyerCarInsurance?: CarInsurance;
  autoTechData?: AutoTechVehicle;
  /**
   * @deprecated
   */
  additionalInformation?: SellerAdditionalInformation;
  sellerAdditionalInformation?: SellerAdditionalInformation;
  buyerAdditionalInformation?: BuyerAdditionalInformation;
  condition?: Condition;
  attachments?: AttachmentsInfo;
  financialAttachments?: AttachmentsInfo;
  settlement?: SettlementFields;
  transaction?: TransactionInfo;
  settlementStatus?: SettlementStatusInfo;
  customerReferences: CustomerReference[];
}

export interface SalesContractWithSignature extends SalesContractBase {
  signature?: SignatureInfo;
}

export interface ContactInfoField {
  section: string;
  name: string;
}

export interface ContactInfoFieldValue {
  field: ContactInfoField;
  value: string;
}

export interface QuarantineHistoryItem {
  fields: ContactInfoFieldValue[];
  added: DateString;
}

export interface QuarantineInfo {
  approvedContactInfoFieldValues: ContactInfoFieldValue[];
  history: QuarantineHistoryItem[];
  quarantined?: DateString;
}

export interface ArchiveInfo {
  archived?: DateString;
  scheduledArchival?: DateString;
}

export interface ArchivalNotificationInfo {
  scheduled?: DateString;
  sent?: DateString;
  notifications: ArchivalNotification[];
}

export interface ArchivalNotification {
  recipientRole: Role;
  sent: DateString;
  messageId: string;
}
export interface SellerAdditionalInformation {
  equipment?: string;
  notes?: string;
  updated: DateString;
}

export interface BuyerAdditionalInformation {
  notes?: string;
  updated: DateString;
}

export interface Condition {
  updated: DateString;
  values: { [key: string]: FieldValue };
}

export interface SalesContractBase {
  id: string;
  experimentSeed: number;
  licensePlateNumber?: string;
  displayLicensePlateNumber?: string;
  initiator: Role;
  schemaType: SchemaType;
  created: DateString;
  buyerToken?: string;
  sellerToken?: string;
  archive?: ArchiveInfo;
  archivalNotification?: ArchivalNotificationInfo;
  quarantine?: QuarantineInfo;
  source: ContractSource;
  trialMembershipOrchestrationHistory: TrialMembershipOrchestrationHistoryItem[];
}
