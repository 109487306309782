import { Location, LocationDescriptorObject } from "@naf/teamscheme";
import { useMemo } from "react";
import { useLocation } from "react-router";
import { AppPages } from "../../routing/AppPages";
import { RETURN_PATH_PARAM, addLocationObjectQueryParam } from "../../shared/utils/Location";
import { useContractToken } from "../ContractParams";
import { ContractToken } from "../ContractToken";
import { getContractLocation } from "../routing/ContractUrls";
import { TargetState } from "../routing/ReturnLocation";

export function useContractLoginCallbackPageLocation(): LocationDescriptorObject {
  const token = useContractToken();
  const location = useLocation();
  return useMemo(() => {
    return getContractLoginCallbackPageLocation(location, token);
  }, [token, location]);
}

export function getContractLoginCallbackPageLocation<T>(
  location: Location<T>,
  { value: token }: ContractToken,
): LocationDescriptorObject<TargetState<T>> {
  const { pathname: returnPath, state: targetState } = location;
  const state: TargetState<T> = { targetState };
  const loginCallback = getContractLocation(
    AppPages.ContractLoginCallback,
    token,
  );

  const withReturnPath = addLocationObjectQueryParam(
    loginCallback,
    new URLSearchParams({ [RETURN_PATH_PARAM]: returnPath }),
  );

  const withTargetState = { ...withReturnPath, state };

  return withTargetState;
}
