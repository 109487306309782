import { ReactNode } from "react";

export function normalizeComponentTree(root: ReactNode): string {
  if (!root) return "";
  if (
    typeof root === "string" ||
    typeof root === "number" ||
    typeof root === "boolean"
  ) {
    return root.toString();
  }
  if ("props" in root) {
    if ("children" in root.props) {
      if (typeof root.props.children === "string") {
        return root.props.children;
      }
      if (Array.isArray(root.props.children)) {
        return root.props.children
          .map((child: ReactNode) => normalizeComponentTree(child))
          .join("");
      }
      return normalizeComponentTree(root.props.children);
    }
    return "";
  }
  return "";
}
