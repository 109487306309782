import { LoadingText } from "@naf/teamscheme";
import { TextLink } from "@naf/teamscheme";
import { SuccessAlert } from "@naf/teamscheme";
import { Button } from "@naf/teamscheme";
import { onDesktop } from "@naf/teamscheme-core";
import { spacing } from "@naf/theme";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import { EVENTS } from "../../../../Tracking";
import { ApiError } from "../../../../api/ApiClient";
import FormGroup from "../../../../forms/FormGroup";
import { LabeledInputComponent } from "../../../../forms/LabeledInputComponent";
import ErrorMessage from "../../../../shared/error/ErrorMessage";
import { capitalizeFirst } from "../../../../utils";
import { useContract, useRoleName, useSchema } from "../../../ContractContext";
import {
  FinnUpdateStatusEnum,
  SectionFieldUpdate,
} from "./UpdateSalesContractFromFinnResponse";
import {
  FinnMismatchLicenseplateNumberError,
  FinnNoCodeError,
  FinnNotFoundError,
  useFinnClient,
} from "./useFinnClient";

export interface FinnSearchProps {
  placeholder: ReactNode;
  licensePlateNumber?: string;
  finnId?: string;
}

const lowerFirst = (value: string) =>
  value.charAt(0).toLowerCase() + value.slice(1);

const SuccessBox = ({
  finnId,
  updates = [],
}: {
  finnId: string;
  updates?: SectionFieldUpdate[];
}) => {
  const schema = useSchema();
  let fieldsChangesString = "Tilgjengelige opplysninger";

  const fieldsChanges = updates
    ?.map((x) => {
      const fieldLabel = schema.getFieldLabel(
        lowerFirst(x.name),
        lowerFirst(x.fieldUpdate.name),
      );
      if (typeof fieldLabel === "string") {
        return lowerFirst(fieldLabel);
      }

      return null;
    })
    .filter((x) => x != null);

  if (fieldsChanges?.length > 0) {
    fieldsChangesString = fieldsChanges
      .join(", ")
      .replace(/,(?=[^,]*$)/, " og ");
  }

  return (
    <SuccessBoxWrapper>
      <SuccessAlert>
        {capitalizeFirst(fieldsChangesString)} ble hentet med FINN-kode {finnId}
        , og fylt inn i kjøpekontrakten. Sørg for at opplysningene er korrekte
        før du signerer.
      </SuccessAlert>
    </SuccessBoxWrapper>
  );
};

export const FinnNotFoundErrorMessage =
  "Fant ingen treff på FINN-koden. Sjekk at koden er korrekt og at kjøretøyet er en personbil som selges privat.";

function FinnErrorText({ error }: { error: ApiError }) {
  if (error instanceof FinnNoCodeError) {
    <span>Du må skrive inn en Finn kode for å kunne søke</span>;
  }
  if (error instanceof FinnNotFoundError) {
    return <span>{FinnNotFoundErrorMessage}</span>;
  }
  if (error instanceof FinnMismatchLicenseplateNumberError) {
    return (
      <span>
        Sjekk at FINN-koden tilhører en annonse for kjøretøyet som skal selges.
      </span>
    );
  }
  return <span>Noe gikk galt</span>;
}

export default function AddDataFromFinn() {
  const [finnId, setFinnId] = useState<string | null>(null);
  const finnClient = useFinnClient();
  const contract = useContract();
  const { self } = useRoleName();
  const { result, isLoading: loading } = finnClient;
  const hasSuccessfulFinnCode =
    contract.finnCode !== null ||
    (finnId && result?.status === FinnUpdateStatusEnum.Success);

  if (hasSuccessfulFinnCode) {
    const finnCode = contract.finnCode ?? finnId;
    if (finnCode) {
      return <SuccessBox finnId={finnCode} updates={result?.updates} />;
    }
  }

  if (loading) {
    return <LoadingTextWrapper text="Henter informasjon fra Finn" />;
  }

  return (
    <div className="VehicleSearch">
      <Placeholder>
        <FormGroup>
          <FinnInputWrapper
            id="FinnCode"
            label={"Hent utfylte opplysninger fra annonsen på Finn.no"}
            description={
              "<p>Dersom det ikke allerede er fylt ut i kontrakten, kan vi ved hjelp av Finn-koden hente kjøretøyets km. stand, pris, og utstyr og fylle det inn i kjøpekontrakten.</p><p>Finn-koden finner du nederst i annonsen.</p>"
            }
            hasError={!!finnClient.error}
          >
            <FinnInnerWrapper>
              <input
                name="FinnCode"
                value={finnId || ""}
                type="text"
                onChange={(event) => {
                  const identifier = event.target.value;
                  setFinnId(identifier);
                }}
                placeholder={"FINN-kode"}
              />
              <Button
                variant="secondary"
                onClick={() => finnClient.fetch(finnId)}
                disabled={!finnId}
                trackEvent={{ ...EVENTS.ADD_FROM_FINN.FETCH_DATA, label: self }}
              >
                Hent opplysninger
              </Button>
            </FinnInnerWrapper>
          </FinnInputWrapper>
          {finnClient.error ? (
            <ErrorMessage>
              <FinnErrorText error={finnClient.error} />{" "}
              <TextLink
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault();
                  finnClient.fetch(finnId);
                }}
                trackEvent={{
                  ...EVENTS.ADD_FROM_FINN.RETRY_FETCH_DATA,
                  label: self,
                }}
              >
                Prøv igjen
              </TextLink>
            </ErrorMessage>
          ) : null}
        </FormGroup>
      </Placeholder>
    </div>
  );
}

const LoadingTextWrapper = styled(LoadingText)`
  margin: ${({ theme }) => theme.spacing.space32} -${({ theme }) =>
    theme.spacing.space8};
  padding: 0 ${({ theme }) => theme.spacing.space8};
  display: block;
`;

const Placeholder = styled.div`
  min-height: ${({ theme }) =>
    theme.fontStyle.article.articleText["line-height"]};
`;

const FinnInputWrapper = styled(LabeledInputComponent)`
  margin: 0 -${({ theme }) => theme.spacing.space8};
  padding: 0 ${({ theme }) => theme.spacing.space8};
  .FormGroup {
    padding: 0 ${({ theme }) => theme.spacing.space8};
    width: 50%;
  }
`;

const FinnInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${onDesktop`
    flex-direction: row;
    gap: ${spacing.space16};
    button {
      min-width: fit-content;
    }
    align-items: center;
  `};

  gap: ${spacing.space8};
  button: {
    min-width: 100%;
  }
  margin-bottom: ${({ theme }) => theme.spacing.space32};
`;

const SuccessBoxWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.space32};
`;
