import { addBreadcrumb } from "@sentry/browser";
import React, {
  useContext,
  useState,
  useCallback,
  useMemo,
  useRef,
  createContext,
} from "react";
import { useAuth } from "../auth/AuthProvider";

const API_URL = "/api";

export const SESSION_ID_HEADER = "X-Session-Id";

export type HttpVerb = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export interface ApiClient {
  send<T>(
    method: HttpVerb,
    uri: string,
    content: unknown,
    headers?: { [key: string]: string },
  ): Promise<T>;
  get<T>(uri: string): Promise<T>;
  post<T>(uri: string, content: unknown): Promise<T>;
  put<T>(uri: string, content: unknown): Promise<T>;
  patch<T>(uri: string, content: unknown): Promise<T>;
  delete<T>(uri: string): Promise<T>;
}

export interface IApiClientContext {
  client: ApiClient;
  sessionId: string | null;
}

type Deferred = {
  promise: Promise<string | null>;
  resolve: (result: string | null) => void;
};

export const ApiClientContext = createContext<IApiClientContext | null>(null);

export class ApiError extends Error {
  constructor(
    message: string,
    public result: unknown,
    public status: number,
  ) {
    super(message);
  }
}

export function weakGuid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const initialSessionId = weakGuid();
const initialSessionIdDeferred: Deferred = {
  resolve: () => {},
  promise: Promise.resolve(initialSessionId),
};

export function ApiClientProvider({
  children,
  baseUrl = API_URL,
}: {
  children: React.ReactNode;
  baseUrl?: string;
}) {
  const authContext = useAuth();
  const jwt = authContext.jwt;
  const authorizationHeader = useMemo(
    () => (jwt ? { Authorization: `Bearer ${jwt}` } : null),
    [jwt],
  );
  const [sessionId, setSessionId] = useState<string | null>(initialSessionId);
  const internalSessionId = useRef<Deferred>(initialSessionIdDeferred);

  const doFetch = useCallback(
    (uri: string, init?: RequestInit) => {
      addBreadcrumb({
        category: "FetchData",
        data: {
          uri,
          init,
        },
      });
      return fetch(`${baseUrl}/${uri}`, init);
    },
    [baseUrl],
  );

  const send = useCallback(
    async (
      method: HttpVerb,
      uri: string,
      content?: unknown,
      headers?: { [key: string]: string },
    ) => {
      let currentSessionId = null;
      if (internalSessionId.current) {
        currentSessionId = await internalSessionId.current.promise;
      }
      if (!currentSessionId) {
        const deferred: Partial<Deferred> = {};
        deferred.promise = new Promise((resolve) => {
          deferred.resolve = resolve;
        });
        internalSessionId.current = deferred as Deferred;
      }

      const allHeaders = {
        ...headers,
        ...(currentSessionId
          ? { [SESSION_ID_HEADER]: currentSessionId }
          : null),
        ...authorizationHeader,
      };

      const initBase =
        content !== undefined
          ? {
              headers: {
                "content-type": "application/json",
                ...allHeaders,
              },
              body: JSON.stringify(content),
            }
          : { headers: allHeaders };

      const init: RequestInit = {
        ...initBase,
        method,
      };

      const response = await doFetch(uri, init);

      // Update session ID after the response has been returned to the caller
      const nextSessionId = response.headers.get(SESSION_ID_HEADER);

      if (nextSessionId) {
        setSessionId(nextSessionId);
      }

      internalSessionId.current?.resolve(nextSessionId);

      if (response.status === 404 && method === "GET") return null;

      const contentType = response.headers.get("content-type");

      const result =
        contentType && contentType.indexOf("application/json") !== -1
          ? await response.json()
          : await response.text();

      const statusString = response.status.toString();

      if (!statusString.startsWith("2")) {
        const error = new ApiError(
          `Status code is not OK: ${response.statusText}. ${
            result.error || ""
          }`,
          result,
          response.status,
        );

        throw error;
      }

      return result;
    },
    [doFetch, authorizationHeader],
  );

  const client = useMemo(
    () => ({
      send,
      get: (uri: string) => send("GET", uri),
      post: (uri: string, content: unknown) => send("POST", uri, content),
      put: (uri: string, content: unknown) => send("PUT", uri, content),
      patch: (uri: string, content: unknown) => send("PATCH", uri, content),
      delete: (uri: string) => send("DELETE", uri),
    }),
    [send],
  );

  const context = useMemo(
    () => ({
      client,
      sessionId,
    }),
    [client, sessionId],
  );

  return (
    <ApiClientContext.Provider value={context}>
      {children}
    </ApiClientContext.Provider>
  );
}

export function useApiClient() {
  const context = useContext(ApiClientContext);

  if (!context) throw new Error("No ApiClientContext found!");

  const { client } = context;

  return client;
}

export function useSessionId() {
  const context = useContext(ApiClientContext);

  if (!context) throw new Error("No ApiClientContext found!");

  const { sessionId } = context;

  return sessionId;
}
