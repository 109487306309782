import { onDesktop } from "@naf/teamscheme-core";
import {
  type HTMLAttributes,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  ContractSource,
  ContractSourceName,
} from "#vehicle-contract/common/lib/model/contract/ContractSource";
import { useAuth } from "../auth/AuthProvider";
import { useCreateContractContext } from "../contract/create-contract/CreateContract";
import Page, { StyledPage } from "../formatting/Page";
import { PageContent } from "../formatting/PageContent";
import StaticHeader from "../layout/header/StaticHeader";
import { FaqSection } from "../static-pages/FaqSection";
import { DummyContractType, DummySection } from "./DummySection";
import { HowToSection } from "./HowToSection";
import { LandingHero } from "./LandingHero";
import { PartnerSection } from "./PartnerSection";
import UpgradeSection from "./UpgradeSection";
import DefaultIllustration from "./illustrations/vehicle_contract_224x176.svg";

enum LandingPageInfoVariant {
  Green = "green",
  Default = "default",
}

interface LandingPageInfoProps extends HTMLAttributes<HTMLDivElement> {
  variant?: LandingPageInfoVariant;
}

const LandingPageInfo = styled(
  ({ variant: _, children, ...props }: LandingPageInfoProps) => (
    <div {...props}>
      <PageContent>{children}</PageContent>
    </div>
  ),
)`
  background: ${({ variant, theme }) =>
    variant === LandingPageInfoVariant.Green
      ? theme.nafColor.primary.dew
      : theme.nafColor.signature.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.space32}
    ${({ theme }) => theme.spacing.space16};
  ${({ theme }) => onDesktop`
    padding: ${theme.spacing.space64} ${theme.spacing.space32};
  `}
`;

export function useForcedLoginForMembershipApp(
  source: ContractSource | ContractSourceName | undefined,
  currentUrl: string,
) {
  const auth = useAuth();
  const { isAuthenticated, loginWithRedirect } = auth;

  const openLogin = useCallback(
    () => loginWithRedirect({ targetUrl: currentUrl }),
    [loginWithRedirect, currentUrl],
  );

  const isMatch = useMemo(() => {
    if (!source) return false;
    if (typeof source === "string") {
      return (
        source.toLowerCase() === ContractSourceName.MembershipApp.toLowerCase()
      );
    }

    return source === ContractSource.MembershipApp;
  }, [source]);

  useEffect(() => {
    if (isMatch && !isAuthenticated) {
      openLogin();
    }
  }, [isMatch, isAuthenticated, openLogin]);
}

const LandingPageWrapper = styled.div`
  ${StyledPage} {
    ${onDesktop`
    padding: 0;
  `}
  }

  ${PageContent} {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    max-width: ${({ theme }) => theme.customBreakpoints.maxHeroWidth}px;
  }
  ${LandingPageInfo}:last-child {
    margin-bottom: ${({ theme }) => theme.spacing.space160};
  }
`;

interface LandingPageTemplateProps {
  illustration?: {
    src: string;
  };
  createContractCard: {
    title: string;
    description: string;
  };
  contractContainsContent?: {
    title?: string;
    contractType?: DummyContractType;
    content: ReactNode;
  };
}

export function LandingPageTemplate(props: LandingPageTemplateProps) {
  const { create } = useCreateContractContext();
  const [showForm, setShowForm] = useState(false);
  const [hasClickedShowForm, setHasClickedShowForm] = useState(false);
  const bodyRef = useRef<HTMLDivElement | null>(null);
  const createCard = useRef<HTMLDivElement | null>(null);
  const [bodyMinHeight, setBodyMinHeight] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!bodyMinHeight && bodyRef.current) {
      const timeout = setTimeout(() => {
        if (bodyRef.current) {
          setBodyMinHeight(bodyRef.current.getBoundingClientRect().height);
        }
      });

      return () => clearTimeout(timeout);
    }
  }, [bodyMinHeight]);

  const scrollToCreateCard = useCallback(() => {
    if (createCard.current) {
      const rect = createCard.current.getBoundingClientRect();

      const isContained =
        rect.y >= 0 &&
        rect.x >= 0 &&
        rect.y + rect.height <= window.innerHeight &&
        rect.x + rect.width <= window.innerWidth;

      if (!isContained) {
        createCard.current.scrollIntoView();
      }
    }
  }, []);

  const location = useLocation();
  const state = location.state as {
    scrollToCreate?: boolean;
  } | null;
  const shouldScrollToCreate = state?.scrollToCreate || false;

  useEffect(() => {
    if (shouldScrollToCreate) {
      scrollToCreateCard();
    }
  }, [shouldScrollToCreate, scrollToCreateCard]);

  useEffect(() => {
    if (showForm && hasClickedShowForm) scrollToCreateCard();
  }, [showForm, hasClickedShowForm, scrollToCreateCard]);

  const lpnInput = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (showForm) {
      const timeout = setTimeout(() => {
        if (lpnInput.current) lpnInput.current.focus();
      });

      return () => clearTimeout(timeout);
    }
  }, [showForm]);

  const source = new URLSearchParams(location.search).get(
    "source",
  ) as ContractSourceName | null;
  const currentUrl = `${location.pathname}${location.search}`;

  useForcedLoginForMembershipApp(source ?? undefined, currentUrl);

  return (
    <LandingPageWrapper>
      <StaticHeader background="green" />
      <Page footer>
        <LandingHero
          bodyRef={bodyRef}
          createCardRef={createCard}
          showForm={showForm}
          setShowForm={(next) => {
            setShowForm(next);
            setHasClickedShowForm(true);
          }}
          create={create}
          lpnInputRef={lpnInput}
          title={props.createContractCard.title}
          description={props.createContractCard.description}
          illustration={{
            src: props.illustration?.src || DefaultIllustration,
            width: 224,
            height: 176,
            maxWidth: 320,
          }}
        />
        <LandingPageInfo variant={LandingPageInfoVariant.Green}>
          <PartnerSection />
        </LandingPageInfo>
        <LandingPageInfo>
          <UpgradeSection />
        </LandingPageInfo>
        <LandingPageInfo variant={LandingPageInfoVariant.Green}>
          <HowToSection />
        </LandingPageInfo>
        <LandingPageInfo>
          <DummySection
            title={props.contractContainsContent?.title}
            contractType={props.contractContainsContent?.contractType}
            onStartNew={() => {
              setHasClickedShowForm(true);
              setShowForm(true);
            }}
          >
            {props.contractContainsContent?.content}
          </DummySection>
        </LandingPageInfo>
        <LandingPageInfo>
          <FaqSection />
        </LandingPageInfo>
      </Page>
    </LandingPageWrapper>
  );
}
