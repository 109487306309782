export const setCookie = (
  cookieName: string,
  cvalue: string,
  exdays: number,
  sameSite: "Lax" | "Strict" | "None",
  isSecure: boolean,
  domain?: string,
) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  let cookieString = isSecure
    ? `${cookieName}=${cvalue};${expires};path=/;SameSite=${sameSite};Secure;`
    : `${cookieName}=${cvalue};${expires};path=/;SameSite=${sameSite};`;

  if (domain) {
    cookieString = `${cookieString}domain=${domain};`;
  }
  document.cookie = cookieString;
};

export const getCookie = (cookieName: string) => {
  const name = `${cookieName}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
