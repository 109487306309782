import { type GetProductsResponse } from "@dtp/membership-service-types";
import { addBreadcrumb } from "@sentry/minimal";
import { useCallback, useEffect, useMemo } from "react";
import { ApiError, type HttpVerb, useApiClient } from "../../api/ApiClient";
import { useAutoLoadingClientBase, useClientBase } from "../../api/ClientBase";
import { type IAuthContext, useAuth } from "../../auth/AuthProvider";
import { type ContractingParty } from "#vehicle-contract/common/lib/model/contract/ContractingParty";

export function useGetContractingPartyByToken(contractToken?: string) {
  const client = useApiClient();
  const { get } = client;
  const clientBase = useClientBase(
    useCallback(
      (token: string) =>
        get<ContractingParty>(`contract/token/${token}/contactInfo`),
      [get]
    )
  );
  const { fetch } = clientBase;
  useEffect(() => {
    if (contractToken) fetch(contractToken);
  }, [fetch, contractToken]);

  return clientBase;
}

export const MEMBERSHIP_WITH_ROADASSISTANCE_PRODUCT_NUMBER = "2020-1121";
export const MEMBERSHIP_NO_ROADASSISTANCE_PRODUCT_NUMBER = "1121";
export const TRIAL_MEMBERSHIP_WITH_ROADASSISTANCE_PRODUCT_NUMBER = "6111";

export const PRODUCT_WHITELIST = [
  MEMBERSHIP_WITH_ROADASSISTANCE_PRODUCT_NUMBER,
  MEMBERSHIP_NO_ROADASSISTANCE_PRODUCT_NUMBER,
];

const options =
  typeof window !== "undefined" &&
  window.location.host === "kjopekontrakt.naf.no"
    ? {
        host: "https://api.naf.no/dtp-contenthub-membershipapi",
        subscriptionKey: "1fc5e7c364104d5db53afe1423dd066c",
      }
    : {
        host: "https://test-api2.naf.no/contenthub-membership",
        subscriptionKey: "f810c9d4673948f49be314afb6ce7e71",
      };

export function useMembershipServiceClient(authContext?: IAuthContext) {
  const apimSubscriptionKeyHeader = useMemo(
    () => ({ "Ocp-Apim-Subscription-Key": options.subscriptionKey }),
    [],
  );

  const jwt = authContext?.jwt;
  const authorizationHeader = useMemo(
    () => (jwt ? { Authorization: `Bearer ${jwt}` } : null),
    [jwt],
  );

  const doFetch = useCallback((uri: string, init?: RequestInit) => {
    addBreadcrumb({
      category: "FetchData",
      data: {
        uri,
        init,
      },
    });
    return fetch(`${options.host}/${uri}`, init);
  }, []);

  const send = useCallback(
    async function <T>(
      method: HttpVerb,
      uri: string,
      content?: unknown,
      headers?: { [key: string]: string },
    ) {
      const allHeaders = {
        ...headers,
        ...apimSubscriptionKeyHeader,
        ...authorizationHeader,
      };

      const initBase =
        content !== undefined
          ? {
              headers: {
                "content-type": "application/json",
                ...allHeaders,
              },
              body: JSON.stringify(content),
            }
          : { headers: allHeaders };

      const init: RequestInit = {
        ...initBase,
        method,
        credentials: "include",
      };

      const response = await doFetch(uri, init);

      if (response.status === 404 && method === "GET") return null;

      const contentType = response.headers.get("content-type");

      const result =
        contentType && contentType.indexOf("application/json") !== -1
          ? await response.json()
          : await response.text();

      const statusString = response.status.toString();

      if (!statusString.startsWith("2")) {
        const error = new ApiError(
          `Status code is not OK: ${response.statusText}. ${
            result?.error ?? ""
          }`,
          result,
          response.status,
        );

        throw error;
      }

      return result as T;
    },
    [apimSubscriptionKeyHeader, authorizationHeader, doFetch],
  );
  const membershipServiceClient = useMemo(() => ({ send }), [send]);
  return membershipServiceClient;
}

export function useMembershipServiceProducts() {
  const authContext = useAuth();
  const { send } = useMembershipServiceClient(authContext);
  return useAutoLoadingClientBase(
    useCallback(
      () =>
        send<GetProductsResponse>("GET", "v2/products", undefined, undefined),
      [send],
    ),
  );
}
