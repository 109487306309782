import { DisableBodyScrollProvider } from "@naf/teamscheme-core";
import { ReactNode } from "react";
import { ApiClientProvider } from "./api/ApiClient";
import { WebSocketClientProvider } from "./api/WebSocketClient";
import CustomerProvider from "./auth/CustomerProvider";
import { CreateContractProvider } from "./contract/create-contract/CreateContract";
import { InputDescriptionModalProvider } from "./forms/InputDescriptionModalContext";
import { BottomPanelProvider } from "./layout/BottomPanel";
import Layout from "./layout/Layout";
import { HeaderAlertsProvider } from "./layout/header/HeaderAlert";
import { AppHistoryProvider } from "./routing/AppHistoryProvider";
import { TrackPageViews } from "./routing/TrackPageViews";

const PROVIDERS = [
  Layout,
  DisableBodyScrollProvider,
  InputDescriptionModalProvider,
  TrackPageViews,
  AppHistoryProvider,
  ApiClientProvider,
  HeaderAlertsProvider,
  BottomPanelProvider,
  WebSocketClientProvider,
  CustomerProvider,
  CreateContractProvider,
];

function Providers({ children }: { children: ReactNode }) {
  return (
    <>
      {PROVIDERS.reduceRight(
        (acc, Provider) => (
          <Provider>{acc}</Provider>
        ),
        children,
      )}
    </>
  );
}

export function AppWrapper({ children }: { children: ReactNode }) {
  return <Providers>{children}</Providers>;
}
